import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import TrackVisibility from "react-on-screen"
import 'animate.css'

import './Header.css';

const Header = () => (
  <div className='app__header app__bglogo app__wrapper section__padding' id="home" >
    <div className='app__wrapper_info header'>
      
      <TrackVisibility once>
           {({ isVisible })=> 
          <div className={isVisible ? "animate__animated animate__fadeInUp" : ""}>
            <SubHeading title="Studio Opening on 01.11.2024"/>
            <h1 className='app__header-h1 mh'>Holistic Massage Therapy, Music & Energy Healing <br /> Featuring Live Music and Fire Show Performances </h1>
            </div> }
          </TrackVisibility>
      
      <p className='p__opensans' style={{margin:'2rem 0'}}></p>
     <button className='custom__button' onClick={()=> window.open('https://lift.bio/morielement',"_blank")} >Let's Connect</button>
    </div>
    <div className='app__wrapper_img moripic'> 
    <TrackVisibility once> 
           {({ isVisible })=> 
          <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
            <img src={images.moribanner} alt="header img" />
            </div> }
          </TrackVisibility>
      
    </div>
  </div>
);

export default Header;
