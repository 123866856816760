
const productsArray = [
    {
        id: "Yumeiho",
        title: "Yu-Mei-Ho-Therapy: 1 Session",
        duration: "60 Minutes",
        price: 120,
        from:"from"
    },
    {
        id: "Yumeiho1",
        title: "Yu-Mei-Ho-Therapy: 1 Person/ABO",
        duration: "5x60 Minutes",
        price: 550
    },
    {
        id: "Yumeiho5",
        title: "Yu-Mei-Ho-Therapy: 1 Person/ABO",
        duration: "10x60 Minutes",
        price: 1000
    }
    ,
    {
        id: "Yoga1",
        title: "Mori Yoga: 1 Person",
        duration: "90 Minutes",
        price: 90,
        
    },
    {
        id: "Gift Coupon",
        title: "Gift Coupon - Gutschein",
        duration: "Give a sense of Balance to your loved ones",
        price: 100,
        from:"from"
    },
];

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };