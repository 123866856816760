import React,{useState} from 'react';
import { FiFacebook, FiInstagram , FiPhone} from 'react-icons/fi';


import { images } from '../../constants';
import './Footer.css';
import TrackVisibility from 'react-on-screen';

const Footer = () => {
   const [show, setShow] = useState(false);
   
   const showImpressum =() => {
    setShow(!show)
   }
  return (

    <div className="app__footer app__bglogo section__padding" id="login">
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">


      <div className="app__footer-links_logo">
      <TrackVisibility partialVisibility>
      {({ isVisible }) =>
        <div className={isVisible ? "animate__animated animate__flip" : ""}>
       <img src={images.handpan} alt="footer_logo"/>
        </div>}
    </TrackVisibility>
        
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
       
        <div className="app__footer-links_icons">
          <FiFacebook onClick={()=> window.open("https://www.facebook.com/people/Mori-Element/100063649175314/?_rdr","_blank")} />
          <FiInstagram onClick={()=> window.open("https://www.instagram.com/mori_element/?hl=en","_blank")}/>
          <FiPhone />
        </div>
      </div>
    </div>

    <div className="footer__copyright">
      <h1 className="app__footer-headtext flex__center"><button onClick={showImpressum} className='impressum'>Impressum</button></h1>
   {show ? (
<div>
   <p className="p__opensans flex__center">Morteza Ketabnevis <br />
        Bachgasse 40/5<br />
        1160 Wien<br />
        <br />
        Unternehmensgegenstand 1:<br />
        Hilfestellung zur Erreichung einer körperlichen bzw. energetischen<br /> Ausgewogenheit mittels div. Methoden lt. Gewerbeordnung (Persönlicher Dienstleister)  
        <br /> GISA-Zahl: 35241492 <br />
          Berufsbezeichnung:
         
          Humanenergetiker<br />
            <br />
          Unternehmensgegenstand 2:<br />
Organisation von Veranstaltungen, Märkten und Messen<br />
GISA-Zahl: 34876527<br />
Berufsbezeichnung: Eventmanagement<br />
<br />
          Kontaktdaten: <br />
          Telefon +43 681 20500866 <br />
          E-Mail: Moriketabnevis@gmail.com <br />
          UID: keine (Kleinunternehmer) <br />
          <br />
          Mitglied der Wirtschaftskammer Wien 
Anwendbare Rechtsvorschriften und Zugang dazu: Gewerbeordnung www.ris.bka.gv.at Gewerbebehörde: Magistrat der Stadt Wien für den 2./20. Bezirk 
        </p>
        
      <p className="p__opensans">2023 MoriElement. All Rights reserved.</p>

      </div>
      ) : ("")}
    </div>
    <p className="design">Web Design & Development by <a href="https://www.jawid.net" target="_blank" rel="noopener noreferrer">Jawid</a> </p>
  </div>
)
  }
;

export default Footer;